import React, {useState, useEffect, useRef} from "react";
import Styles from "../styles/ofbstyles.module.css";
import FStyles from "../styles/finance.module.css";
import {talk, format_currency_new, load_fetch} from "../ofb_util";
import CategoryReportItem from "./CategoryReportItem";
import DateInput from "./DateInput";
import {PayeeContext} from "./PayeeContext";

//TODO: In Budgeted account transfers, I don't have the actual amounts, only the budgeted amounts


const Budget = (props) => {
    const [budgetData, setBudgetData] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [customRangeSelectValue, setCustomRangeSelectValue] = useState("THIS_MONTH");
    const [daysInReportRange, setDaysInReportRange] = useState(0);
    const [payees, setPayees] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [categoryTransactions, setCategoryTransactions] = useState([]);
    const [showNetWorthReport, setShowNetWorthReport] = useState(false);
    const [netWorthDate, setNetWorthDate] = useState("");
    const [netWorthAmount, setNetWorthAmount] = useState(0);
    const [netWorthOverTimeData, setNetWorthOverTimeData] = useState([]);
    const canvasRef = useRef(null);


    //USE EFFECTS
    useEffect(() => {
        if(selectedCategory) {
            //Load transactions for this category
            load_category_transactions();
        }
    }, [selectedCategory]);
    useEffect(() => {
        if(!netWorthDate) {
            return;
        }

        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_NET_WORTH',
            netWorthDate: netWorthDate
        }

        load_fetch(url, postData, (data) => {
            console.log(data);
            setNetWorthAmount(data);
        });
    }, [netWorthDate]);
    useEffect(() => {
        get_payees();
        generate_custom_range();
        //get_budget_data();
    }, []);
    useEffect(() => {
        if(canvasRef.current) {
            draw_on_canvas();
        }
    }, [canvasRef.current])
    useEffect(() => {
        console.log("HERE");
        get_budget_data();
        if(selectedCategory) {
            load_category_transactions();
        }
    }, [startDate, endDate]);
    useEffect(() => {
        generate_custom_range();
    }, [customRangeSelectValue]);
    useEffect(() => {
        if(showNetWorthReport) {
            get_net_worth_over_time();
        }
    }, [showNetWorthReport]);
    useEffect(() => {
        if(canvasRef.current) {
            draw_on_canvas();
        }
    }, [budgetData]);

    //AJAX FUNCTIONS
    const get_net_worth_over_time = () => {
        let url = 'Finance_Handler.php';
        let operation = 'GET_NET_WORTH_OVER_TIME_REPORT';
        talk(url, operation, null, get_net_worth_over_time_handler);
    }
    const get_net_worth_over_time_handler = (operation, sent_data, return_data) => {
        if(!return_data['ERROR']) {
            setNetWorthOverTimeData(return_data);
        } else {
            setNetWorthOverTimeData([]);
        }
    }
    const get_payees = () => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_PAYEES'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setPayees(data);
            } else {
                console.log(data);
                //setAccounts([]);
            }
        });
    }
    const get_budget_data = () => {
        let postData = {
            operation: 'GET_BUDGET_REPORT_DATA',
            startDate: startDate,
            endDate: endDate
        }
        talk("Finance_Handler.php", "GET_BUDGET_REPORT_DATA", postData, get_budget_data_handler);
    }
    const get_budget_data_handler = (operation, data, return_data) => {
        console.log("BUDGET REPORT TRANSACTIONS BY CATEGORY:");
        console.log(return_data);

        if(!return_data['ERROR'] && !return_data['error']) {
            //setBudgetData(newData);
            setBudgetData(return_data);
        } else {
            setBudgetData(false);
        }
    }


    const load_category_transactions = () => {
        let url = 'Finance_Handler.php';
        let postData = {
            operation: 'GET_TRANSACTIONS_FOR_CATEGORY',
            startDate: startDate,
            endDate: endDate,
            category_id: selectedCategory
        }
        load_fetch(url, postData, (data) => {
            setCategoryTransactions(data);
        });
    }

    //OTHER FUNCTIONS
    const navigate_to_accounts = () => {
        document.location.href='./finance';
    }
    const cancel_category_report = () => {
        setSelectedCategory(0);
    }
    const toggle_net_worth_report = () => {
        setShowNetWorthReport(!showNetWorthReport);
    }
    const generate_custom_range = () => {
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        let end_date = null;
        switch(customRangeSelectValue) {
            case "THIS_MONTH":
                month = (today.getMonth() + 1).toString().padStart(2, "0");
                setStartDate(year + "-" + month + "-01");
                end_date = new Date(year, today.getMonth() + 1, 0).getDate();
                setEndDate(year + "-" + month + "-" + end_date);
                setDaysInReportRange(today.getDate());
                break;
            case "LAST_MONTH":
                month--;
                if(month === -1) {
                    year--;
                    month = 11;
                }
                setStartDate(year + "-" + (month + 1).toString().padStart(2, "0") + "-01");
                end_date = new Date(year, month + 1, 0).getDate();
                setEndDate(year + "-" + (month + 1).toString().padStart(2, "0") + "-" + end_date);
                setDaysInReportRange(end_date);
                break;
            case "THIS_YEAR":
                setStartDate(year + "-01-01");
                setEndDate(year + "-12-31");
                setDaysInReportRange(get_day_of_year(today));
                break;
            case "LAST_YEAR":
                setStartDate((year - 1) + "-01-01");
                setEndDate((year - 1) + "-12-31");
                setDaysInReportRange(year % 4 === 0 ? 366 : 365);
                break;
            case "CUSTOM_RANGE":
                //TODO: Calculate the number of days between the two
                break;
        }
    }
    const get_day_of_year = (date_object) => {
        let start = new Date(date_object.getFullYear(), 0, 0);
        let diff = date_object - start;
        let oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
    }
    const draw_on_canvas = () => {
        let graphWidth = 600;
        let graphHeight = 400;
        let graphMargin = 20;
        let maxPercentage = prep_budget_graph_info();
        let one_hundred_percent = (graphHeight - (graphMargin * 2)) / maxPercentage;
        let required_graph_width = budgetData['EXPENSE'].length * 30 + 40;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0,0, canvas.width, canvas.height);
        canvas.width = (required_graph_width > graphWidth) ? required_graph_width : graphWidth;
        canvas.height = graphHeight;

        for(let i = 0; i < budgetData['EXPENSE'].length; i++) {
            draw_bar(i, budgetData['EXPENSE'][i], one_hundred_percent);
        }

    }
    const prep_budget_graph_info = () => {
        if(!budgetData['EXPENSE']) {
            return;
        }

        let maxPercentage = 1;
        for(let i = 0; i < budgetData['EXPENSE'].length; i++) {
            let item  = budgetData['EXPENSE'][i];
            if(item['total'] / item['budgeted_total'] > maxPercentage) {
                maxPercentage = item['total'] / item['budgeted_total'];
            }
        }

        return maxPercentage;
    }
    const draw_bar = (index, budget_item, one_hundred_percent_pixels) => {
        let canvas_padding = 20;
        const canvas = canvasRef.current;
        var ctx = canvas.getContext("2d");

        let item_percent = budget_item['total'] / budget_item['budgeted_total'];


        let bar_height = item_percent * one_hundred_percent_pixels;

        if(bar_height > one_hundred_percent_pixels) {
            ctx.fillStyle = "#ff7b7b";
        } else {
            ctx.fillStyle = "#87ff7b";
        }

        //Draw Bars
        ctx.fillRect((index * 30 + canvas_padding), (400 - bar_height - canvas_padding), 20, bar_height);
        ctx.strokeStyle = "black";
        ctx.strokeRect((index * 30 + canvas_padding), (400 - canvas_padding - one_hundred_percent_pixels), 20, one_hundred_percent_pixels);

        //Draw Pro Rating Line
        let day_of_month = new Date().getDate();
        let days_in_month = days_in_current_month();
        let prorate = day_of_month / days_in_month;
        ctx.strokeStyle = "blue";
        ctx.beginPath();
        let budget_prorate_height = Math.abs(one_hundred_percent_pixels * prorate);
        ctx.moveTo((index * 30 + canvas_padding - 1), 400 - budget_prorate_height - canvas_padding);
        ctx.lineTo((index * 30 + canvas_padding + 32), 400 - budget_prorate_height - canvas_padding);
        ctx.stroke();


        //Draw Label
        ctx.font = "11px Arial";
        ctx.fillStyle = "black";
        ctx.save();
        ctx.translate(index * 30 + 34, 360);
        ctx.rotate(-Math.PI/2);
        ctx.textAlign = "left";
        ctx.fillText(budget_item['category_name'],0, 0);
        ctx.restore();
    }
    const days_in_current_month = () => {
        const now = new Date(); // Get the current date
        const currentYear = now.getFullYear(); // Get the current year
        const currentMonth = now.getMonth(); // Get the current month (0-indexed, so January is 0)

        // Create a date for the first day of the next month
        const nextMonth = new Date(currentYear, currentMonth + 1, 1);

        // Subtract one day from the first day of the next month to get the last day of the current month
        const lastDayCurrentMonth = new Date(nextMonth - 1);

        // Return the day of the month (which is the number of days in the current month)
        return lastDayCurrentMonth.getDate();
    }

    if(budgetData) {
        return (
            <PayeeContext.Provider value={payees}>
                <button className={Styles.ofb_button} onClick={navigate_to_accounts}>Back to Accounts</button>
                <div className={FStyles.graph_canvas_div}>
                    <canvas ref={canvasRef} width="600" height="400" className={FStyles.graph_canvas}/>
                </div>
                <div className={Styles.ofb_budget_report_date_limiter_holder}>
                    <div>Start Date: </div><DateInput
                        name="start_date"
                        className=""
                        transaction_date={startDate}
                        onDateChange={setStartDate}
                    />
                    <div>End Date: </div><DateInput
                    name="end_date"
                    className=""
                    transaction_date={endDate}
                    onDateChange={setEndDate}
                    />
                    <div>Preset Range: </div><select
                    onChange={(event) => setCustomRangeSelectValue(event.target.value)}
                    value={customRangeSelectValue}
                    >
                    <option value="CUSTOM_RANGE">Custom Range</option>
                    <option value="THIS_MONTH">This Month</option>
                    <option value="LAST_MONTH">Last Month</option>
                    <option value="THIS_YEAR">This Year</option>
                    <option value="LAST_YEAR">Last Year</option>
                </select>
                </div>

                <div className={Styles.ofb_net_worth_report_holder}>
                    <button className={Styles.ofb_button} onClick={toggle_net_worth_report}>Show Net Worth Report</button>
                    <div className={showNetWorthReport ? Styles.ofb_net_worth_report : Styles.ofb_hide}>
                        <div className={Styles.ofb_net_worth_report_row}>
                            <DateInput
                                name="net_worth_date"
                                className=""
                                transaction_date={netWorthDate}
                                onDateChange={setNetWorthDate}
                            />
                            <div>{format_currency_new(netWorthAmount)}</div>
                        </div>
                        <div className={Styles.ofb_net_worth_report_row}>
                            <h3>Net Worth Over Time</h3>
                            <div className={Styles.ofb_net_worth_columns}>
                            {netWorthOverTimeData.map((item, index) => (
                                <div key={index} className={Styles.ofb_net_worth_report_row}>
                                <div className={Styles.ofb_net_worth_cell}>{item['DATE']}</div>
                                <div className={Styles.ofb_net_worth_cell}>{format_currency_new(item['NET_WORTH']['NET_WORTH'])}</div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>


                {!selectedCategory ? (
                    <div>
                        <div className={Styles.ofb_finance_category_report_holder}>
                            <h2>Expense Report</h2>
                            <div className={`${Styles.ofb_finance_category_report} ${Styles.ofb_border_top}`}>
                                <div className={Styles.ofb_bold}>Category</div>
                                <div className={Styles.ofb_bold}>Spent</div>
                                <div className={Styles.ofb_bold}>Budgeted</div>
                                <div className={Styles.ofb_bold}>Difference</div>
                            </div>
                            {budgetData['EXPENSE'].map((item, index) => (
                                <CategoryReportItem
                                    days_in_report_range={daysInReportRange}
                                    data={item}
                                    key={index}
                                    shaded={index % 2 === 0 ? true : false}
                                    update_budget_gui={get_budget_data}
                                    handle_category_report_selection={setSelectedCategory}
                                />
                            ))}
                            <CategoryReportItem
                                key='expense_report_total_line'
                                shaded={true}
                                is_summary_row={true}
                                data={{budgeted_total:budgetData['BUDGETED_EXPENSES'], total:budgetData['TOTAL_EXPENSE'], category_name:'Total Expenses:', category_id:-1}}
                            />
                        </div>
                        <div className={Styles.ofb_finance_category_report_holder}>
                            <h2>Income Report</h2>
                            {budgetData['INCOME'].map((item, index) => (
                                <CategoryReportItem
                                    data={item}
                                    key={index}
                                    shaded={index % 2 === 0 ? true : false}
                                />
                            ))}
                            <CategoryReportItem
                                key='income_report_total_line'
                                shaded={true}
                                is_summary_row={true}
                                data={{budgeted_total:budgetData['BUDGETED_INCOME'], total:budgetData['TOTAL_INCOME'], category_name:'Total Income:', category_id:-1}}
                            />
                        </div>
                        <div className={Styles.ofb_finance_category_report_holder}>
                            <h2>Summary</h2>
                            <div>
                                Total Income: {format_currency_new(budgetData['TOTAL_INCOME'])}
                            </div>
                            <div>
                                Total Expenses: {format_currency_new(budgetData['TOTAL_EXPENSE'])}
                            </div>
                            <div>
                                Grand Total: {format_currency_new(budgetData['GRAND_TOTAL'])}
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div>
                            <button
                                className={Styles.ofb_button}
                                onClick={cancel_category_report}
                            >-Back to Budget-</button>
                            {categoryTransactions.length > 0 && (
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Payee</td>
                                            <td>Account</td>
                                            <td>Memo</td>
                                            <td>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {categoryTransactions.map((item, index) => (
                                        <tr key={index} className={Styles.ofb_line_below}>
                                            <td>{item.transaction_date}</td>
                                            <td>{item.payee_name}</td>
                                            <td>{item.account_name}</td>
                                            <td>{item.memo}</td>
                                            <td>{format_currency_new(item.amount)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    )}


            </PayeeContext.Provider>
        )
    } else {
        return <>No data</>
    }
}

export default Budget;