import logo from './logo.svg';
import './styles/App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {useEffect, useState} from 'react';
import Layout from "./layout";
import Home from "./home";
import Life from "./life";
import Language from "./language";
import IntroductionToLinguistics from "./introduction-to-linguistics";
import Church from "./church";
import Today from "./Today/today";
import PseudosToday from "./Today/pseudos_today";
import Calendar from "./calendar.js";
import Signin from "./signin";
import Todo from "./todo";
import TodoSimple from "./todo_simple";
import { AuthContext } from "./auth_context";
import { UserProvider } from "./language/UserContext";
import Config from "./ofb_config.json";
import Health from "./health";
import Finance from "./finance";
import Library from "./library";
import Learning from "./learning";
import Budget from "./Finance/Budget";
import BibleMemory from "./BibleMemory";
import Projects from "./ToDo/Projects";
import TODOManageOverdue from "./ToDo/TODOManageOverdue";
import TodoQuick from "./ToDo/TodoQuick";
import TodoActiveSimple from "./todo_active_simple";
import Cleaning from "./cleaning";
import Meals from "./Meals/Meals";
import Pulse from "./Pulse";
import PulseServer from "./Pulse/Pulse_Server";
import Print_Labels from "./Library/print_labels";
import MenuLandscape from "./PseudOS/menu_landscape";
import PseudosLayout from "./PseudOS/pseudos_layout";
import PseudosHome from "./PseudOS/pseudos_home";
import Prayer from "./Prayer/prayer";
import Discipleship from "./Discipleship/Discipleship";
import Cycles from "./Cycles/cycles";
import Overview from "./overview";
import Shopping from "./Shopping/shopping";
import Games from "./Games/Games";
import Mike_Polo_Devotional from "./Discipleship/mike_polo_devotional";
import ImportTransactions from "./Finance/ImportTransactions";
import AccountView from "./Finance/AccountVIew";
import ReconcileAccount from "./Finance/ReconcileAccount";
import ReconciliationReport from "./Finance/ReconciliationReport";
import WritingTextbook from "./language/writing_textbook/writing-textbook";
import LanguageLayout from "./language/language_layout";
import LanguageHome from "./language/language_home";

function App() {
    const [authStatus, setAuthStatus] = useState(null);

    useEffect(() => {
        checkLoggedIn();
    },[]);

    function checkLoggedIn() {
        let url;

        //Manage Development vs Production Environments
        //if(window.location.hostname == "localhost") {
            //setAuthStatus(true);
            //return;
            //url = Config.local_api_url + "auth_handler.php";
        //} else {
            url = Config.api_url + "auth_handler.php";
        //}

        const options = {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({operation: 'LOGIN_STATUS'})
        };

        fetch(url, options)
            .then(result => result.json())
            .then(data => {
                //console.log("AUTH DATA: ");
                //console.log(data);
                if (data['LOGIN_STATUS']) {
                    setAuthStatus(true);
                } else {
                    setAuthStatus(false);
                }
            })
            .catch(error => {
                setAuthStatus(false);
                console.log('ERROR: ' + error);
            })
    }

    if(window.location.pathname.indexOf('pseudos') !== -1) {
        return (
            <AuthContext.Provider value={[authStatus, setAuthStatus]}>
                <BrowserRouter value={[authStatus, setAuthStatus]}>
                    <Routes>
                        {/*<Route path="/" element={<PseudosLayout />}>*/}
                        <Route path="pseudos" element={<PseudosHome authStatus={authStatus} />} />
                        <Route path="pseudos-today" element={<PseudosToday authStatus={authStatus} />} />
                        {/*</Route>*/}
                    </Routes>
                </BrowserRouter>
            </AuthContext.Provider>
        )
    } else if (/^\/lang(\/|$)/.test(window.location.pathname)) {
        console.log(window.location.pathname);
        return (
            <UserProvider>
                <BrowserRouter basename="/" >
                    <Routes>
                        <Route path="/" element={<LanguageLayout />}>
                        <Route path="lang" element={<LanguageHome />} />
                        <Route path="lang/writing-textbook" element={<WritingTextbook />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </UserProvider>
        )
    } else {
        return (
            <AuthContext.Provider value={[authStatus, setAuthStatus]}>
                <BrowserRouter basename="/" value={[authStatus, setAuthStatus]}>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Home />} />
                            <Route path="language" element={<Language />} />
                            <Route path="newlife" element={<Life />} />
                            <Route path="introduction-to-linguistics" element={<IntroductionToLinguistics />} />
                            <Route path="church" element={<Church />} />
                            <Route path="todo" element={<TodoSimple authStatus={authStatus}/>} />
                            <Route path="today" element={<Today />} />
                            <Route path="signin" element={<Signin />} />
                            <Route path="calendar" element={<Calendar authStatus={authStatus}/>} />
                            <Route path="health" element={<Health authStatus={authStatus} />} />
                            <Route path="finance" element={<Finance authStatus={authStatus} />} />
                            <Route path="library" element={<Library authStatus={authStatus} />} />
                            <Route path="library-print-labels" element={<Print_Labels authStatus={authStatus} />} />
                            <Route path="budget" element={<Budget authStatus={authStatus} />} />
                            <Route path="bible-memory" element={<BibleMemory authStatus={authStatus} />} />
                            <Route path="projects" element={<Projects authStatus={authStatus} />} />
                            <Route path="todo-manage-overdue" element={<TODOManageOverdue authStatus={authStatus} />} />
                            <Route path="todo-quick" element={<TodoQuick authStatus={authStatus} />} />
                            <Route path="learning" element={<Learning authStatus={authStatus} />} />
                            <Route path="cleaning" element={<Cleaning authStatus={authStatus} />} />
                            <Route path="todo-active-simple" element={<TodoActiveSimple authStatus={authStatus} />} />
                            <Route path="meals" element={<Meals authStatus={authStatus} />} />
                            <Route path="pulse" element={<Pulse authStatus={authStatus} />} />
                            <Route path="pulse-server" element={<PulseServer authStatus={authStatus} />} />
                            <Route path="prayer" element={<Prayer authStatus={authStatus} />} />
                            <Route path="discipleship" element={<Discipleship authStatus={authStatus} />} />
                            <Route path="cycles" element={<Cycles authStatus={authStatus} />} />
                            <Route path="overview" element={<Overview authStatus={authStatus} />} />
                            <Route path="shopping" element={<Shopping authStatus={authStatus} />} />
                            <Route path="games" element={<Games authStatus={authStatus} />} />
                            <Route path="mike_polo_devotional" element={<Mike_Polo_Devotional authStatus={authStatus} />} />
                            <Route path="import_transactions" element={<ImportTransactions authStatus={authStatus} />} />
                            <Route path="account_view" element={<AccountView authStatus={authStatus} />} />
                            <Route path="reconcile_account" element={<ReconcileAccount authStatus={authStatus} />} />
                            <Route path="reconciliation_report" element={<ReconciliationReport authStatus={authStatus} />} />
                            <Route path="writing-textbook" element={<WritingTextbook authStatus={authStatus} />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AuthContext.Provider>
        );
    }

}

export default App;
